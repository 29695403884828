/* team css */
#overview {
  font-style: italic;
  font-weight: 400;
}

#teamImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 50px 0px;
}

#bottomWrapper {
  margin: 0 !important;
  max-width: 100%;
}

@media(max-width: 600px) {
  #teamImage {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bottomWrapper {
    margin: 0 !important;
    max-width: 100%;
    text-align: center;
  }

  .col-sm-9 {
    margin-left: 35px !important;
  }

  #overview {
    font-style: italic;
    font-weight: 400;
    text-align: center;
  }
}

/* Filter column */
#filterBar {
  list-style-type: none;
  padding: 0px;
  font-family: 'Karla';
}

.active {
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}

.inactive {
  padding-bottom: 10px;
  font-size: 24px;
}

.active:hover {
  cursor: pointer;
}

.inactive:hover {
  cursor: pointer;
}

/* Team grid CSS */
.columnContent {
  text-align: center;
  margin-bottom: 60px;
  margin-right: 0;
  margin-left: 0;
}

.colorWords {
  color: #3db8f8;
}

.nameMargin {
  margin-top: 10px;
}

.adjustCard  {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  width: auto;
}

.row {
  height: auto;
}

/* use to change all text sizes */

.overallTeam {
  font-size: 12pt;
}

.closingIcon {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
  height: 25px;
}

.closingIcon:hover {
  cursor: pointer;
}

.modalName {
  font-family: 'Fira Sans', sans-serif;
  color: "#33B7Fb",
}

.modalTeam {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 800;
  margin-bottom: 24px;
}

.modalText {
  font-size: 0.8em;
  font-family: 'Fira Sans', sans-serif;
}

.socialLinks {
  color: #33B7FB;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95px;
}

.modalPortfolio {
  margin-top: 25px;
  background-color: #33B7FB !important;
  color: white;
  border-color: white !important;
  font-weight: 800;
  border-radius: 0;
  font-family: 'Karla';
}

#desktopView {
  display: none;
}

.teamCard {
  padding-left: 25px !important;
  margin-bottom: 50px;
}

.position {
  font-weight: 600;
  font-size: 2.5em;
  color: #33B7FB;
  text-align: left;
}

#team-logo {
  max-width: 175px;
  margin: 20px 0px 20px 0px;
}

.execTitle {
  font-weight: 400;
  color: #7F7F7F;
  /* padding-left: 30px; */
  text-align: left;
  padding-bottom: 20px;
  padding-top: 10px;
}

.committeeTitle {
  font-weight: 400;
  color: #7F7F7F;
  /* padding-left: 30px; */
  padding-top: 20px;
}

.committeeNames {
  font-weight: 300;
  font-size: 1.6em;
  color: #7F7F7F;
  text-align: left;
  /* padding-left: 30px; */
}

.picture {
  width: 100%;
  height: auto;
  /* cursor: pointer; */
  align-content: center;
}

.execMemberName {
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
  color: #33B7FB;
  margin-bottom: 1px;
}

.teamPictures {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

/* Center image */

.presidentBox {
  display: block;
  margin: auto;
}

.team-col {
  padding: 0 1rem;
}

.leftPic1, .leftPic2, .rightPic1, .rightPic2 {
  -webkit-box-shadow: -10px 10px 15px 0px rgba(127, 127, 127, 0.3);
  -moz-box-shadow: -10px 10px 15px 0px rgba(127, 127, 127, 0.3);
  box-shadow: -10px 10px 15px 0px rgba(127, 127, 127, 0.3);
}

li.nav-item button.nav-link {
  background-color: white;
  border: none;
}

li.nav-item button.nav-link:focus {
  background-color: white;
  outline: none!important;
}

li.nav-item button.nav-link:hover {
  background-color: white;
  outline: none!important;
  cursor: pointer;
}

@media (min-width: 576px) {
  .team-modal.modal-dialog {
    max-width: 300px;
  }
  .teamCard {
    margin-bottom: 50px;
  }
}

@media (max-width: 550px) {
  .modalPicture {
    display: none;
  }
}

/* Moves stuff to left and right on desktop */

@media (min-width: 768px) {
  .team-col {
    padding: 0 4rem;
  }
  .team-modal.modal-dialog {
    max-width: 800px;
  }
  .mobileView {
    display: none !important;
  }
  #desktopView {
    display: block;
  }
  .leftPic1, .leftPic2 {
    margin-top: 60px;
  }
  .rightPic1, .rightPic2 {
    margin-top: 0px;
  }
  .execMemberNames, .committeeNames {
    font-size: 1.8em;
    padding-bottom: 5px;
    padding-top: 20px;
    color: #7F7F7F;
  }
  .execTitle, .committeeTitle {
    font-weight: 200;
    color: #7F7F7F;
    text-align: left;
    font-size: 1.5em;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .extraPadding {
    padding-left: 30px;
  }
  #presidentTitle {
    margin-top: 50px;
  }
  #presidentName {
    padding-top: 20px;
  }
}

.topImage {
  width: 55%;
  height: 40%;
}