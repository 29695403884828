.memberTitle {
  color: rgb(164, 151, 255);
}
.memberHeading {
  color: rgb(22, 0, 174);
}

.col-sm {
  text-align: center;
  transform: skewY(6.5deg);
  text-align: left;
}

#membership {
  float: left;
  width: 60%;
}

#memberSkew {
  background-color: rgb(214, 208, 255, 0.4);
  margin-top: 3%;
  margin-bottom: 10%;
  padding-top: 8%;
  padding-bottom: 13%;
  transform: skewY(-6.5deg);
}

.event-image {
  text-align: center;
  padding-left: 10px;
  max-width: 100%;
  max-height: 80%;
}

#image-div {
  text-align: center;
  float: right;
  margin-top: 20px;
}

.imageWrapper {
  width: 75%;
  height: 100%;
}

.appImage {
  width: 40%;
  height: 100%;
}

#eventVisuals {
  margin-left: -15px;
}