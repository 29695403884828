.page-links {
  color: inherit;
  text-decoration: none;
}

.page-links:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: 28px;
  font-style: 'Karla';
}

.row {
  width: 100%;
  height: 350px;
}

#image-div {
  float: left;
  width: 50%;
}

#accelerate-career, #other-pages {
  float: right;
  width: 50%;
  padding-left: 100px;
  padding-top: 60px;
}

#rocketImage {
  width: 85%;
  padding-top: 40px;
}

#homeTop {
  padding-bottom: 100px;
}

#mainHeading {
  font-size: 64px;
}

#programHeader {
  padding-bottom: 10px;
}

.subtext {
  font-size: 14px;
}

#box {
  background-color: lightgray;
  width: 55px;
  height: 55px;
  margin-right: 50px;
}

.logoSection {
 margin-top: -40px !important;
}

.smallLogo {
  width: 15%;
  height: 15%;
} 

.logoText {
  margin-top: 20px;
  margin-left: 30px;
  width: 80%;
}

.homeWrapper {
  padding-bottom: 150px;
}