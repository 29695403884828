.image {
    max-height: 500px;
}

.title {
    margin-top: 1em;
}

.centered {
    text-align: center;
}
.body {
    font-size: 20px;
}
.tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

blockquote {
    background: #f9f9f9;
    border-left: 7px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 20px;
    padding-left: 30px;
    padding-top: 20px;
}

#post {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding-top: 1px;
    padding-bottom: 5px;
}

.back-btn {
    background-color: white !important;
    color: black !important;
    border-radius: 3px !important;
    border: 1px solid white;
}

.back-btn:hover {
    box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0.2);
}