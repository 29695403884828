@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap);
.htHeaderFont {
    font-family: 'Red Hat Display';
}

.htBodyFont {
    font-family: 'Karla';
}

.htEvent {
    color: #7ecffa;
}

.htMembership {
    color: #aaa2f8;
}

.htMentorship {
    color: #6b95f7;
}

.htNavColor {
    color: black;
}

.htHeading {
    font-size: 64px;
}

.htSubHeading {
    font-size: 48px;
}

.htBody {
    font-size: 24px;
}

.sitePadding {
    padding-left: 80px;
    padding-right: 80px;
}

@media(max-width: 600px) {
    .sitePadding {
        padding-left: 25px;
        padding-right: 25px;
    }
}






#siteBody {
    padding: 25px;
}
.page-links {
  color: inherit;
  text-decoration: none;
}

.page-links:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: 28px;
  font-style: 'Karla';
}

.row {
  width: 100%;
  height: 350px;
}

#image-div {
  float: left;
  width: 50%;
}

#accelerate-career, #other-pages {
  float: right;
  width: 50%;
  padding-left: 100px;
  padding-top: 60px;
}

#rocketImage {
  width: 85%;
  padding-top: 40px;
}

#homeTop {
  padding-bottom: 100px;
}

#mainHeading {
  font-size: 64px;
}

#programHeader {
  padding-bottom: 10px;
}

.subtext {
  font-size: 14px;
}

#box {
  background-color: lightgray;
  width: 55px;
  height: 55px;
  margin-right: 50px;
}

.logoSection {
 margin-top: -40px !important;
}

.smallLogo {
  width: 15%;
  height: 15%;
} 

.logoText {
  margin-top: 20px;
  margin-left: 30px;
  width: 80%;
}

.homeWrapper {
  padding-bottom: 150px;
}
/* team css */
#overview {
  font-style: italic;
  font-weight: 400;
}

#teamImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 50px 0px;
}

#bottomWrapper {
  margin: 0 !important;
  max-width: 100%;
}

@media(max-width: 600px) {
  #teamImage {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bottomWrapper {
    margin: 0 !important;
    max-width: 100%;
    text-align: center;
  }

  .col-sm-9 {
    margin-left: 35px !important;
  }

  #overview {
    font-style: italic;
    font-weight: 400;
    text-align: center;
  }
}

/* Filter column */
#filterBar {
  list-style-type: none;
  padding: 0px;
  font-family: 'Karla';
}

.active {
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}

.inactive {
  padding-bottom: 10px;
  font-size: 24px;
}

.active:hover {
  cursor: pointer;
}

.inactive:hover {
  cursor: pointer;
}

/* Team grid CSS */
.columnContent {
  text-align: center;
  margin-bottom: 60px;
  margin-right: 0;
  margin-left: 0;
}

.colorWords {
  color: #3db8f8;
}

.nameMargin {
  margin-top: 10px;
}

.adjustCard  {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  width: auto;
}

.row {
  height: auto;
}

/* use to change all text sizes */

.overallTeam {
  font-size: 12pt;
}

.closingIcon {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
  height: 25px;
}

.closingIcon:hover {
  cursor: pointer;
}

.modalName {
  font-family: 'Fira Sans', sans-serif;
  color: "#33B7Fb",
}

.modalTeam {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 800;
  margin-bottom: 24px;
}

.modalText {
  font-size: 0.8em;
  font-family: 'Fira Sans', sans-serif;
}

.socialLinks {
  color: #33B7FB;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95px;
}

.modalPortfolio {
  margin-top: 25px;
  background-color: #33B7FB !important;
  color: white;
  border-color: white !important;
  font-weight: 800;
  border-radius: 0;
  font-family: 'Karla';
}

#desktopView {
  display: none;
}

.teamCard {
  padding-left: 25px !important;
  margin-bottom: 50px;
}

.position {
  font-weight: 600;
  font-size: 2.5em;
  color: #33B7FB;
  text-align: left;
}

#team-logo {
  max-width: 175px;
  margin: 20px 0px 20px 0px;
}

.execTitle {
  font-weight: 400;
  color: #7F7F7F;
  /* padding-left: 30px; */
  text-align: left;
  padding-bottom: 20px;
  padding-top: 10px;
}

.committeeTitle {
  font-weight: 400;
  color: #7F7F7F;
  /* padding-left: 30px; */
  padding-top: 20px;
}

.committeeNames {
  font-weight: 300;
  font-size: 1.6em;
  color: #7F7F7F;
  text-align: left;
  /* padding-left: 30px; */
}

.picture {
  width: 100%;
  height: auto;
  /* cursor: pointer; */
  align-content: center;
}

.execMemberName {
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
  color: #33B7FB;
  margin-bottom: 1px;
}

.teamPictures {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

/* Center image */

.presidentBox {
  display: block;
  margin: auto;
}

.team-col {
  padding: 0 1rem;
}

.leftPic1, .leftPic2, .rightPic1, .rightPic2 {
  box-shadow: -10px 10px 15px 0px rgba(127, 127, 127, 0.3);
}

li.nav-item button.nav-link {
  background-color: white;
  border: none;
}

li.nav-item button.nav-link:focus {
  background-color: white;
  outline: none!important;
}

li.nav-item button.nav-link:hover {
  background-color: white;
  outline: none!important;
  cursor: pointer;
}

@media (min-width: 576px) {
  .team-modal.modal-dialog {
    max-width: 300px;
  }
  .teamCard {
    margin-bottom: 50px;
  }
}

@media (max-width: 550px) {
  .modalPicture {
    display: none;
  }
}

/* Moves stuff to left and right on desktop */

@media (min-width: 768px) {
  .team-col {
    padding: 0 4rem;
  }
  .team-modal.modal-dialog {
    max-width: 800px;
  }
  .mobileView {
    display: none !important;
  }
  #desktopView {
    display: block;
  }
  .leftPic1, .leftPic2 {
    margin-top: 60px;
  }
  .rightPic1, .rightPic2 {
    margin-top: 0px;
  }
  .execMemberNames, .committeeNames {
    font-size: 1.8em;
    padding-bottom: 5px;
    padding-top: 20px;
    color: #7F7F7F;
  }
  .execTitle, .committeeTitle {
    font-weight: 200;
    color: #7F7F7F;
    text-align: left;
    font-size: 1.5em;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .extraPadding {
    padding-left: 30px;
  }
  #presidentTitle {
    margin-top: 50px;
  }
  #presidentName {
    padding-top: 20px;
  }
}

.topImage {
  width: 55%;
  height: 40%;
}
.contactForm > h2 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 500;
    font-size: 50px;
    color: #6E9FFF;
  }
  
  .contactUs-text {
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-size: 20px;
    font-weight: 300;
    padding-top: 10px;
  }
  
  textarea {
    height: 125px;
  }
  
  .desktopView {
    display: none;
    visibility: hidden;
  } 
  
  .hideMe {
    display: none;
    visibility: hidden;
  }
  
  #submit-button {
    margin-top: 20px;
    font-weight: 500;
    background-color: #6E9FFF;
    color: white;
    border: 2px solid white;
    border-radius: 20px;
    width: 100px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
  }
  
  #submit-button:hover {
    background-color: white;
    border: 2px solid #6E9FFF;
    color: #6E9FFF;
  }
  
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  input::-ms-input-placeholder { /* IE 10+ */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  
  input::-moz-placeholder { /* Firefox 18- */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  
  @media(min-width: 768px) {
    .contactForm, #desktopView {
      margin-top: 50px;
      margin-bottom: 0px;
    }
  
    #desktopView {
      display: block;
    }
  
    #desktopView > h2 {
      font-family: 'Fira Sans', sans-serif;
      font-weight: 500;
      font-size: 50px;
      color: #6E9FFF;
    }
  
    .contactForm {
      padding-left: 50px;
    }
  
    .followUsIcons {
      height: 55px;
    }
  
    .followUsRow {
      margin-bottom: 20px;
    }
  
    .followUsRow > a > p {
      margin-left: 20px;
    }
  
    .followUsRow > a > p {
      font-family: 'Fira Sans', sans-serif;
      font-weight: 300;
      font-size: 20px;
      color: #6E9FFF;
    }
  
    .followUsRow > a, .followUsRow > a > p {
      display: inline-block;
    }
    
  }
#huskyNav .nav-link {
    color: black;
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
}

.nav-item {
    padding: 0 !important;
}

#mainNav {
    padding-top: 40px !important;
    padding-left: 0 !important;
    padding-bottom: 40px !important;
    padding-right: 0 !important;
}

.dropdown-menu {
    background-color: #EDEDED !important;
}

.dropdown-item .nav-link {
    font-size: 20px;
    padding: 5px !important;
    text-align: center !important;
}

.dropdown-item:active {
    background-color: white !important;
    color: black !important;
}

.selected {
    border-radius: 30px;
    background-color:#EDEDED !important;
}

.dropdown-item .selectedDropdown {
    border-radius: 30px;
    background-color: white !important;
}

.navOption {
    padding-left: 15px;
}

.navLogo {
    width: 80%;
    height: 80%;
}
.title {
  color:rgb(94, 94, 245);
}

.event-image {
  text-align: center;
  padding-top: 50px;
  padding-left: 10px;
  max-width: 100%;
}
.memberTitle {
  color: rgb(164, 151, 255);
}
.memberHeading {
  color: rgb(22, 0, 174);
}

.col-sm {
  text-align: center;
  -webkit-transform: skewY(6.5deg);
          transform: skewY(6.5deg);
  text-align: left;
}

#membership {
  float: left;
  width: 60%;
}

#memberSkew {
  background-color: rgb(214, 208, 255, 0.4);
  margin-top: 3%;
  margin-bottom: 10%;
  padding-top: 8%;
  padding-bottom: 13%;
  -webkit-transform: skewY(-6.5deg);
          transform: skewY(-6.5deg);
}

.event-image {
  text-align: center;
  padding-left: 10px;
  max-width: 100%;
  max-height: 80%;
}

#image-div {
  text-align: center;
  float: right;
  margin-top: 20px;
}

.imageWrapper {
  width: 75%;
  height: 100%;
}

.appImage {
  width: 40%;
  height: 100%;
}

#eventVisuals {
  margin-left: -15px;
}
.mentorTitle {
  color: rgb(79, 191, 255);
}

.mentorHeading {
  color: rgb(0, 139, 218);
}

.col-sm {
  text-align: center;
  -webkit-transform: skewY(6.5deg);
          transform: skewY(6.5deg);
  text-align: left;
}

#mentorship {
  float: left;
  width: 60%;
}

#mentorSkew {
  background-color: rgb(79, 191, 255, 0.4);
  margin-top: 3%;
  margin-bottom: 10%;
  padding-top: 6%;
  padding-bottom: 6%;
  -webkit-transform: skewY(-6.5deg);
          transform: skewY(-6.5deg);
}

#image-div {
  text-align: center;
}

.event-image {
  text-align: center;
  padding-left: 10px;
  max-width: 100%;
  max-height: 90%;
}

.skewImage {
  width: 120%;
  height: 100%;
  margin-left: -100px;
}
.whoCanAttend {
    font-size: 28;
    font-weight: bold;
    font-style: italic;
    padding-right: 20px;
}

#calendar-wrapper {
    padding-bottom: 50px;
}

.inlineShift {
    display: inline-block;
}

.colorBox {
    width: 17px;
    height: 17px;
    display: inline-block;
    background-color: #ccc;
    margin-right: 10px;
}

.topLegend {
    display: flex;
    justify-content: space-between;
}
/* Sponsor Header/Subheader section */
#sponsorHeader {
    padding-bottom: 60px;
}

#sponsorSub {
  padding-bottom: 20px;
}

#sponsoredByText {
  padding-bottom: 20px;
}

#marginOverride {
  margin-bottom: 60px !important;
}

@media(max-width: 600px) {
  #marginOverride {
    margin-bottom: 60px !important;
    margin-left: 23px !important;
  }
}

#sponsorInfoText {
  margin: 0 !important;
}

#sponsorInfo {
  margin-right: 0px !important;
}

.sponsorshipText {
  font-family: 'Karla';
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

/* Sponsor Logos */
.s-img {
  max-width: 200px;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.sponsor-row {
  justify-content: center;
}

.logos-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}


#logoImage {
  height: 100px;
}

#sponsors-logo {
  max-width: 275px;
  margin: 20px 0px 20px 0px;
}

/* Bottom Section "Why sponsor?" and quotes*/
#whyDescription {
  font-style: 'Karla';
  font-size: 24px;
}

.sponsors > h3 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 600;
    font-size: 40px;
    color: #3db8f8;
    margin-bottom: 40px;
}

.whySponsor > h3 {
  margin-bottom: 35px;
  font-size: 48px;
  font-weight: bold;
}

#join-button {
  font-size: 22px;
  background-color: #aaa2f8;
  font: 'Karla';
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin-bottom: 50px;
  margin-top: 30px;
}

#joinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Quote boxes */
#quoteOne {
  background-color: #6b95f7;
}

#quoteTwo {
  background-color: #aaa2f8;
}

#quoteThree {
  background-color: #7ecffa;
}

.quoteBox {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  max-height: 188px;
  height: 188px;
  width: 358px;
}

.testimonial {
  font-family: "Karla";
  font-size: 20px;
  color: white;
  font-style: italic;
}

/* Media rules */
@media(min-width: 576px) {
    .sponsors > h3 {
        font-size: 30px;
    }

    .sponsors {
      margin-top: 80px;
    }
}

@media(max-width: 600px) {
  .quoteBox {
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 50px;
    max-height: 188px;
    height: 188px;
    max-width: 300px;
  }

  #sponsorSub {
    text-align: center;
  }

  #sponsorHeader {
    text-align: center;
  }
}

@media(min-width: 415px) {
    .sponsor-img {
        max-width: 150px;
        max-height: 65px;
        width: auto;
        height: auto;
        margin-bottom: 30px;
    }
}

@media(max-width: 414px) {
    .sponsor-img {
        max-width: 90px;
        max-height: 30px;
        width: auto;
        height: auto;
        margin-bottom: 10px;
    }
}

.blog-container {
  display: flex;
  padding: 1em;
  border-bottom: 1px solid lightgray;
}

.blog-container, h1 {
  font-family: "Fira Sans" !important;
}

.blog-container:hover {
  cursor: pointer;
}

.post {
  width: 100%;
  height: 350px;
}
.postimage {
  max-width: 90%;
  border-radius: 10px;
}

.blog-container .postimage {
  margin-bottom: 1em;
}

.postinfo {
  float: center;
}
.posttags {
  vertical-align: bottom;
}

.tag {
  padding: 3px 10px;
  border: #7F7F7F solid 2px;
  margin: 0.5em;
  border-radius: 10px;
}

.tag-area {
  display: flex;
  flex-wrap: wrap;
}

.bold {
  font-weight: bold;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

.no-underline:hover {
  text-decoration: none;
  color: inherit;
}

.spinner-area {
  text-align: center;
  margin-top: 5em;
  margin-bottom: 5em;
}

.links-area {
  display: flex;
}

.back-btn {
  background-color: #45B9F5 !important;
  color: white !important;
  border-radius: 3px !important;
}

.preview-links {
  display: flex;
  flex-wrap: wrap;
}

.preview-link {
  font-size: 12px;
  border: #7F7F7F solid 2px;
  display: flex;
  border-radius: 4px;
  margin: 0.5em;
  align-items: center;
  padding: 0.3em;
}

.no-blogs {
  display: flex;
  justify-content: center;
}

.preview-link:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

.file-logo {
  max-width: 40px;
  max-height: 40px;
}

@media (min-width: 768px) {
  #post .postimage {
      max-width: 40%;
  }

  .blog-container .postimage {
      margin-bottom: 0;
  }

  .preview-link {
      font-size: 16px;
  }
  
  .nav-link {
      min-width: 110px !important;
  }
}

@media (min-width: 1200px) {
  .nav-link {
      min-width: 140px !important;
  }
}

.image {
    max-height: 500px;
}

.title {
    margin-top: 1em;
}

.centered {
    text-align: center;
}
.body {
    font-size: 20px;
}
.tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

blockquote {
    background: #f9f9f9;
    border-left: 7px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 20px;
    padding-left: 30px;
    padding-top: 20px;
}

#post {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding-top: 1px;
    padding-bottom: 5px;
}

.back-btn {
    background-color: white !important;
    color: black !important;
    border-radius: 3px !important;
    border: 1px solid white;
}

.back-btn:hover {
    box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0.2);
}
