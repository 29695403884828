.mentorTitle {
  color: rgb(79, 191, 255);
}

.mentorHeading {
  color: rgb(0, 139, 218);
}

.col-sm {
  text-align: center;
  transform: skewY(6.5deg);
  text-align: left;
}

#mentorship {
  float: left;
  width: 60%;
}

#mentorSkew {
  background-color: rgb(79, 191, 255, 0.4);
  margin-top: 3%;
  margin-bottom: 10%;
  padding-top: 6%;
  padding-bottom: 6%;
  transform: skewY(-6.5deg);
}

#image-div {
  text-align: center;
}

.event-image {
  text-align: center;
  padding-left: 10px;
  max-width: 100%;
  max-height: 90%;
}

.skewImage {
  width: 120%;
  height: 100%;
  margin-left: -100px;
}