/* Sponsor Header/Subheader section */
#sponsorHeader {
    padding-bottom: 60px;
}

#sponsorSub {
  padding-bottom: 20px;
}

#sponsoredByText {
  padding-bottom: 20px;
}

#marginOverride {
  margin-bottom: 60px !important;
}

@media(max-width: 600px) {
  #marginOverride {
    margin-bottom: 60px !important;
    margin-left: 23px !important;
  }
}

#sponsorInfoText {
  margin: 0 !important;
}

#sponsorInfo {
  margin-right: 0px !important;
}

.sponsorshipText {
  font-family: 'Karla';
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

/* Sponsor Logos */
.s-img {
  max-width: 200px;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.sponsor-row {
  justify-content: center;
}

.logos-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}


#logoImage {
  height: 100px;
}

#sponsors-logo {
  max-width: 275px;
  margin: 20px 0px 20px 0px;
}

/* Bottom Section "Why sponsor?" and quotes*/
#whyDescription {
  font-style: 'Karla';
  font-size: 24px;
}

.sponsors > h3 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 600;
    font-size: 40px;
    color: #3db8f8;
    margin-bottom: 40px;
}

.whySponsor > h3 {
  margin-bottom: 35px;
  font-size: 48px;
  font-weight: bold;
}

#join-button {
  font-size: 22px;
  background-color: #aaa2f8;
  font: 'Karla';
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin-bottom: 50px;
  margin-top: 30px;
}

#joinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Quote boxes */
#quoteOne {
  background-color: #6b95f7;
}

#quoteTwo {
  background-color: #aaa2f8;
}

#quoteThree {
  background-color: #7ecffa;
}

.quoteBox {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  max-height: 188px;
  height: 188px;
  width: 358px;
}

.testimonial {
  font-family: "Karla";
  font-size: 20px;
  color: white;
  font-style: italic;
}

/* Media rules */
@media(min-width: 576px) {
    .sponsors > h3 {
        font-size: 30px;
    }

    .sponsors {
      margin-top: 80px;
    }
}

@media(max-width: 600px) {
  .quoteBox {
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 50px;
    max-height: 188px;
    height: 188px;
    max-width: 300px;
  }

  #sponsorSub {
    text-align: center;
  }

  #sponsorHeader {
    text-align: center;
  }
}

@media(min-width: 415px) {
    .sponsor-img {
        max-width: 150px;
        max-height: 65px;
        width: auto;
        height: auto;
        margin-bottom: 30px;
    }
}

@media(max-width: 414px) {
    .sponsor-img {
        max-width: 90px;
        max-height: 30px;
        width: auto;
        height: auto;
        margin-bottom: 10px;
    }
}
