@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');

.htHeaderFont {
    font-family: 'Red Hat Display';
}

.htBodyFont {
    font-family: 'Karla';
}

.htEvent {
    color: #7ecffa;
}

.htMembership {
    color: #aaa2f8;
}

.htMentorship {
    color: #6b95f7;
}

.htNavColor {
    color: black;
}

.htHeading {
    font-size: 64px;
}

.htSubHeading {
    font-size: 48px;
}

.htBody {
    font-size: 24px;
}

.sitePadding {
    padding-left: 80px;
    padding-right: 80px;
}

@media(max-width: 600px) {
    .sitePadding {
        padding-left: 25px;
        padding-right: 25px;
    }
}





