.contactForm > h2 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 500;
    font-size: 50px;
    color: #6E9FFF;
  }
  
  .contactUs-text {
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-size: 20px;
    font-weight: 300;
    padding-top: 10px;
  }
  
  textarea {
    height: 125px;
  }
  
  .desktopView {
    display: none;
    visibility: hidden;
  } 
  
  .hideMe {
    display: none;
    visibility: hidden;
  }
  
  #submit-button {
    margin-top: 20px;
    font-weight: 500;
    background-color: #6E9FFF;
    color: white;
    border: 2px solid white;
    border-radius: 20px;
    width: 100px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
  }
  
  #submit-button:hover {
    background-color: white;
    border: 2px solid #6E9FFF;
    color: #6E9FFF;
  }
  
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  input::-ms-input-placeholder { /* IE 10+ */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  
  input::-moz-placeholder { /* Firefox 18- */
    font-family: 'Fira Sans', sans-serif;
    color: #7F7F7F;
    font-weight: 400;
  }
  
  @media(min-width: 768px) {
    .contactForm, #desktopView {
      margin-top: 50px;
      margin-bottom: 0px;
    }
  
    #desktopView {
      display: block;
    }
  
    #desktopView > h2 {
      font-family: 'Fira Sans', sans-serif;
      font-weight: 500;
      font-size: 50px;
      color: #6E9FFF;
    }
  
    .contactForm {
      padding-left: 50px;
    }
  
    .followUsIcons {
      height: 55px;
    }
  
    .followUsRow {
      margin-bottom: 20px;
    }
  
    .followUsRow > a > p {
      margin-left: 20px;
    }
  
    .followUsRow > a > p {
      font-family: 'Fira Sans', sans-serif;
      font-weight: 300;
      font-size: 20px;
      color: #6E9FFF;
    }
  
    .followUsRow > a, .followUsRow > a > p {
      display: inline-block;
    }
    
  }