.blog-container {
  display: flex;
  padding: 1em;
  border-bottom: 1px solid lightgray;
}

.blog-container, h1 {
  font-family: "Fira Sans" !important;
}

.blog-container:hover {
  cursor: pointer;
}

.post {
  width: 100%;
  height: 350px;
}
.postimage {
  max-width: 90%;
  border-radius: 10px;
}

.blog-container .postimage {
  margin-bottom: 1em;
}

.postinfo {
  float: center;
}
.posttags {
  vertical-align: bottom;
}

.tag {
  padding: 3px 10px;
  border: #7F7F7F solid 2px;
  margin: 0.5em;
  border-radius: 10px;
}

.tag-area {
  display: flex;
  flex-wrap: wrap;
}

.bold {
  font-weight: bold;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

.no-underline:hover {
  text-decoration: none;
  color: inherit;
}

.spinner-area {
  text-align: center;
  margin-top: 5em;
  margin-bottom: 5em;
}

.links-area {
  display: flex;
}

.back-btn {
  background-color: #45B9F5 !important;
  color: white !important;
  border-radius: 3px !important;
}

.preview-links {
  display: flex;
  flex-wrap: wrap;
}

.preview-link {
  font-size: 12px;
  border: #7F7F7F solid 2px;
  display: flex;
  border-radius: 4px;
  margin: 0.5em;
  align-items: center;
  padding: 0.3em;
}

.no-blogs {
  display: flex;
  justify-content: center;
}

.preview-link:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

.file-logo {
  max-width: 40px;
  max-height: 40px;
}

@media (min-width: 768px) {
  #post .postimage {
      max-width: 40%;
  }

  .blog-container .postimage {
      margin-bottom: 0;
  }

  .preview-link {
      font-size: 16px;
  }
  
  .nav-link {
      min-width: 110px !important;
  }
}

@media (min-width: 1200px) {
  .nav-link {
      min-width: 140px !important;
  }
}
