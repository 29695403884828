.whoCanAttend {
    font-size: 28;
    font-weight: bold;
    font-style: italic;
    padding-right: 20px;
}

#calendar-wrapper {
    padding-bottom: 50px;
}

.inlineShift {
    display: inline-block;
}

.colorBox {
    width: 17px;
    height: 17px;
    display: inline-block;
    background-color: #ccc;
    margin-right: 10px;
}

.topLegend {
    display: flex;
    justify-content: space-between;
}