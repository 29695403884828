#huskyNav .nav-link {
    color: black;
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
}

.nav-item {
    padding: 0 !important;
}

#mainNav {
    padding-top: 40px !important;
    padding-left: 0 !important;
    padding-bottom: 40px !important;
    padding-right: 0 !important;
}

.dropdown-menu {
    background-color: #EDEDED !important;
}

.dropdown-item .nav-link {
    font-size: 20px;
    padding: 5px !important;
    text-align: center !important;
}

.dropdown-item:active {
    background-color: white !important;
    color: black !important;
}

.selected {
    border-radius: 30px;
    background-color:#EDEDED !important;
}

.dropdown-item .selectedDropdown {
    border-radius: 30px;
    background-color: white !important;
}

.navOption {
    padding-left: 15px;
}

.navLogo {
    width: 80%;
    height: 80%;
}